@import url("https://use.typekit.net/gjg4bak.css");
@import "./variables.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

.webApp {
  width: 100%;
  height: 100%;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-smoothing: antialiased;
  font-family: $fontStack;
  font-weight: $fontWeightBase;
  font-style: normal;
  color: $colorBase;
  font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
  line-height: $fontLineHeightBase;
  letter-spacing: 0.05vw;

  strong {
    font-weight: $fontWeightBold;
  }

  a {
    position: relative;
    font-style: normal;
    color: $colorBase;
    text-decoration: none;
    text-decoration: underline;
  }

  .button {
    position: relative;
    height: clamp($fontSizeMin * 1.6, $fontSizeScale * 1.6, $fontSizeMax * 1.6);
    display: inline-block;
    padding: 0 1px;
    margin: 0;
    cursor: pointer;
    overflow: visible;
    background-color: transparent;
    transition: background-color 0.1s ease;
    border: none;
    font-family: $fontStack;
    font-size: clamp(
      $fontSizeMin * 0.7,
      $fontSizeScale * 0.7,
      $fontSizeMax * 0.7
    );
    font-weight: $fontWeightBase;
    line-height: 2;
    letter-spacing: 0.05vw;
    color: $colorBase;

    &:focus {
      outline: none;
    }

    svg {
      display: block;
      margin: 0;
      height: clamp(
        $fontSizeMin * 1.6,
        $fontSizeScale * 1.6,
        $fontSizeMax * 1.6
      );
      width: auto;
      overflow: visible;

      .outlined {
        fill: $colorBase;
      }

      .filled {
        fill: none;
      }
    }

    &:hover {
      color: var(--background-color);

      svg {
        .outlined {
          fill: none;
        }

        .filled {
          fill: $colorBase;
        }
      }
    }

    &.isActive {
      color: var(--background-color);

      svg {
        .outlined {
          fill: none;
        }

        .filled {
          fill: $colorBase;
        }
      }
    }

    &--logo {
      height: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      text-transform: uppercase;
      vertical-align: text-top;

      svg {
        transform: translateY(
          clamp(
            -$fontSizeMin * 0.45,
            -$fontSizeScale * 0.45,
            -$fontSizeMax * 0.45
          )
        );
        -moz-transform: translateY(
          clamp(
            -$fontSizeMin * 0.75,
            -$fontSizeScale * 0.75,
            -$fontSizeMax * 0.75
          )
        );
      }
    }

    &--instructions,
    &--close {
      position: fixed;
      z-index: 2;
      top: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      right: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      width: clamp(
        $fontSizeMin * 1.6,
        $fontSizeScale * 1.6,
        $fontSizeMax * 1.6
      );
      font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      text-align: center;

      svg {
        transform: translateY(
          clamp(-$fontSizeMin * 0.3, -$fontSizeScale * 0.3, -$fontSizeMax * 0.3)
        );
      }
    }

    &--grow {
      position: relative;
      border: none;

      font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      line-height: $fontLineHeightBase;
      text-transform: uppercase;

      svg {
        display: block;
        height: clamp($fontSizeMin * 2, $fontSizeScale * 2, $fontSizeMax * 2);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;

        .line {
          fill: none;
          stroke: $colorBase;
          stroke-miterlimit: 10;
          stroke-dasharray: 0.6 0.6;
          stroke-width: 4px;

          transition: stroke-width 0.5s ease-out;
        }
      }

      &:hover {
        color: $colorBase;

        svg .line {
          stroke-width: 14.25px;
        }
      }
    }

    &--pause,
    &--play,
    &--download,
    &--preset {
      width: clamp(
        $fontSizeMin * 1.6,
        $fontSizeScale * 1.6,
        $fontSizeMax * 1.6
      );
    }
  }

  .input {
    &--message {
      width: 100%;
      padding: clamp(
          $fontSizeMin * 0.25,
          $fontSizeScale * 0.25,
          $fontSizeMax * 0.25
        )
        0 clamp($fontSizeMin * 0.15, $fontSizeScale * 0.15, $fontSizeMax * 0.15)
        0;
      font-size: clamp($fontSizeMin * 2, $fontSizeScale * 2, $fontSizeMax * 2);
      color: $colorBase;
      text-transform: uppercase;
      text-align: center;
      border: none;
      border-bottom: clamp($borderWidthMin, $borderWidthScale, $borderWidthMax)
        solid transparent;
      background: transparent;
      background: repeating-linear-gradient(
        to right,
        $colorBase,
        $colorBase 1px,
        transparent 2px,
        transparent 5px
      );

      &::-webkit-input-placeholder {
        color: $colorBase;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: $colorBase;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: $colorBase;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $colorBase;
        opacity: 1;
      }

      &:focus {
        outline: none;
        background: transparent;
        border-bottom-color: $colorBase;

        &::-webkit-input-placeholder {
          color: transparent;
        }

        &:-moz-placeholder {
          color: transparent;
        }

        &::-moz-placeholder {
          color: transparent;
        }

        &:-ms-input-placeholder {
          color: transparent;
        }
      }
    }

    &--mutation {
      display: block;
      width: 100%;
      margin: clamp(
          $fontSizeMin * 0.75,
          $fontSizeScale * 0.75,
          $fontSizeMax * 0.75
        )
        0;
    }

    &Label {
      font-size: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );

      &--mutationLevel {
        display: block;
        width: 100%;
        text-align: center;
      }

      &--mutationScale {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 33.333% 33.333% 33.333%;

        .regular {
          text-align: left;
        }

        .wild {
          text-align: center;
        }

        .overgrown {
          text-align: right;
        }
      }
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: clamp($borderWidthMin, $borderWidthScale, $borderWidthMax);
      cursor: pointer;
      animate: 0.2s;
      background: $colorBase;
      border: none;
    }

    &::-moz-range-track {
      width: 100%;
      height: clamp($borderWidthMin, $borderWidthScale, $borderWidthMax);
      cursor: pointer;
      animate: 0.2s;
      background: $colorBase;
      border: none;
    }

    &::-ms-track {
      width: 100%;
      height: clamp($borderWidthMin, $borderWidthScale, $borderWidthMax);
      cursor: pointer;
      animate: 0.2s;
      background: $colorBase;
      border: none;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      cursor: pointer;
      height: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );
      width: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );
      border: none;
      border-radius: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      background: $colorBase;
      transform: translateY(-50%);
      margin-top: clamp(
        $borderWidthMin/2,
        $borderWidthScale/2,
        $borderWidthMax/2
      );
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      cursor: pointer;
      height: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );
      width: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );
      border: none;
      border-radius: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      background: $colorBase;
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      cursor: pointer;
      height: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );
      width: clamp(
        $fontSizeMin * 0.75,
        $fontSizeScale * 0.75,
        $fontSizeMax * 0.75
      );
      border: none;
      border-radius: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
      background: $colorBase;
      transform: translateY(-50%);
      margin-top: clamp(
        $borderWidthMin/2,
        $borderWidthScale/2,
        $borderWidthMax/2
      );
    }
  }

  .sr-only {
    position: fixed;
    top: -1000px;
    left: -1000px;
  }

  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
    justify-items: center;
    overflow-y: auto;
    padding: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);

    > * {
      width: 100%;

      @media (min-width: 768px) {
        width: $contentWidth;
      }
    }

    pointer-events: none;
    header,
    button,
    .inputGroup {
      pointer-events: initial;
    }
  }

  .header {
    text-align: center;

    .typeBelow {
      font-weight: $fontWeightBold;

      &:after {
        content: "";
        display: inline-block;
        width: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
        height: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M98.48,50.58a5.49,5.49,0,0,0-7.33,0l-36,33.12V4.77a5.2,5.2,0,0,0-10.37,0V83.7l-36-33.12a5.49,5.49,0,0,0-7.33,0,4.5,4.5,0,0,0,0,6.75L46.34,98.6a5.5,5.5,0,0,0,7.33,0L98.48,57.33A4.5,4.5,0,0,0,98.48,50.58Z'/%3E%3C/svg%3E");
        background-size: 100% auto;
        background-repeat: no-repeat;
        margin: auto 0.5vw
          clamp(-$fontSizeMin / 5, -$fontSizeScale / 5, -$fontSizeMax / 5) 0.5vw;
      }
    }

    .fullStop {
      font-weight: $fontWeightBold;
    }

    .button--close {
      display: none;
    }
  }

  .main {
    display: grid;
    grid-auto-flow: row;
    gap: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
    padding: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax) 0;

    .inputGroup {
      &--grow {
        display: grid;
        justify-content: center;
      }

      &--options {
        position: relative;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: clamp(
          $borderWidthMin * 4,
          $borderWidthScale * 4,
          $borderWidthMax * 4
        );
        margin-top: clamp(
          $fontSizeMin / 2,
          $fontSizeScale / 2,
          $fontSizeMax / 2
        );

        .button--upload {
          position: absolute;
          right: 0;
        }
      }

      &--controls {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr auto auto auto;
        justify-content: stretch;
        gap: clamp(
          $borderWidthMin * 4,
          $borderWidthScale * 4,
          $borderWidthMax * 4
        );

        > * {
          display: grid;
          grid-auto-flow: row;
          grid-template-rows: 1fr auto;
          align-content: end;
          text-align: center;

          label {
            height: clamp(
              $fontSizeMin * 0.75,
              $fontSizeScale * 0.75,
              $fontSizeMax * 0.75
            );
            margin-top: clamp(
              $fontSizeMin / 2,
              $fontSizeScale / 2,
              $fontSizeMax / 2
            );
          }
        }
      }

      &--surface,
      &--growth {
        margin-top: clamp($fontSizeMin/2, $fontSizeScale/2, $fontSizeMax/2);

        label {
          margin-top: clamp(-$fontSizeMin, -$fontSizeScale, -$fontSizeMax);
        }
      }

      &--presets {
        text-align: center;

        .buttonGroup {
          display: grid;
          grid-auto-flow: column;
          gap: clamp($borderWidthMin, $borderWidthScale, $borderWidthMax);
        }
      }

      &--pause,
      &--play {
        display: grid;
        grid-auto-flow: column;
        gap: clamp($borderWidthMin, $borderWidthScale, $borderWidthMax);
      }
    }

    .instructions {
      text-align: center;

      h2 {
        font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
        font-weight: $fontWeightBase;
        line-height: 1;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
      }

      ol {
        margin: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax) 0;
        padding: 0;
        list-style-position: inside;
      }
    }

    .scaffoldUpload {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;

      .button--submitUpload {
        margin-top: 2em;
      }
    }
  }

  .loadingSpinner {
    display: none;
    position: fixed;
    z-index: 2;
    top: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
    left: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
    width: clamp($fontSizeMin * 1.4, $fontSizeScale * 1.4, $fontSizeMax * 1.4);
    padding: clamp(
      $fontSizeMin * 0.15,
      $fontSizeScale * 0.15,
      $fontSizeMax * 0.15
    );
    shape-rendering: auto;

    svg {
      display: block;
      width: 100%;

      rect {
        fill: $colorBase;
      }
    }
  }

  //States

  &.isInitial {
    .header {
      .origin,
      .instructions {
        display: none;
      }
    }

    .main {
      align-content: center;

      .inputGroup {
        &--options,
        &--controls {
          display: none;
        }
      }

      .instructions,
      .scaffoldUpload {
        display: none;
      }
    }
  }

  &.isGrowing {
    .header {
      .description,
      .typeBelow,
      .fullStop,
      .credits,
      .origin {
        display: none;
      }
    }

    .main {
      align-content: space-between;
      padding-top: clamp(
        $fontSizeMin / 2,
        $fontSizeScale / 2,
        $fontSizeMax / 2
      );
      padding-bottom: 0;

      .inputGroup {
        &--mutation,
        &--grow {
          display: none;
        }

        &--controls {
          align-self: end;
        }
      }

      .input--message {
        font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
        background: none;
        border-bottom-color: $colorBase;
      }

      .instructions,
      .scaffoldUpload {
        display: none;
      }
    }

    // Hide UI on user inactivity

    .header span,
    .header .button--instructions,
    .main {
      opacity: 1;
      transition: opacity 1.5s ease;
      > * {
        pointer-events: initial;
      }
    }

    &.isUserInactive {
      .header span,
      .header .button--instructions,
      .main {
        opacity: 0;
        > * {
          pointer-events: none;
        }
      }
    }
  }

  &.isGrowthOngoing {
    .header .loadingSpinner {
      display: block;
    }
  }

  &.isInstructing {
    .header {
      .typeBelow,
      .fullStop,
      .button--instructions {
        display: none;
      }

      .button--close {
        display: block;
      }
    }

    .main {
      align-content: center;

      .inputGroup {
        &--message,
        &--mutation,
        &--grow,
        &--controls {
          display: none;
        }
      }

      .scaffoldUpload {
        display: none;
      }
    }
  }

  &.isUploading {
    .header {
      .description,
      .typeBelow,
      .fullStop,
      .credits,
      .origin,
      .button--instructions {
        display: none;
      }

      .button--close {
        display: block;
      }
    }

    .main {
      align-content: center;

      .inputGroup {
        &--message,
        &--mutation,
        &--grow,
        &--controls {
          display: none;
        }
      }

      .instructions {
        display: none;
      }

      .scaffoldUploadControls {
      }
    }
  }

  // Mobile warning

  .mobileWarning {
    text-align: center;

    @media (orientation: portrait) and (min-width: 768px),
      (orientation: landscape) and (min-width: 1024px) {
      display: none;
    }
  }

  @media (orientation: portrait) and (max-width: 767px),
    (orientation: landscape) and (max-width: 1023px) {
    &.isInitial,
    &.isGrowing,
    &.isInstructing,
    &.isUploading {
      .header {
        .typeBelow,
        .fullStop,
        .button--instructions {
          display: none;
        }

        .origin {
          display: inline;
        }
      }

      .main {
        align-content: start;

        .inputGroup {
          &--message,
          &--mutation,
          &--grow,
          &--controls,
          &--upload {
            display: none;
          }
        }
      }
    }
  }

  // Colour picker

  .popoverContainer {
    position: relative;
  }

  .popover {
    width: 395px;
    padding: 20px;
    z-index: 5;
    position: absolute;
    left: 50%;
    background: $colorBase;
    font-size: 14px;
    color: white;
    border-radius: clamp(
      $fontSizeMin * 0.75,
      $fontSizeScale * 0.75,
      $fontSizeMax * 0.75
    );
    opacity: 0;
    pointer-events: none;

    &--attachBottom {
      top: -10px;
      transform: translate(-50%, -100%);
    }
    &--attachTop {
      top: 20px;
      transform: translate(
        -50%,
        clamp($fontSizeMin, $fontSizeScale, $fontSizeMax)
      );
    }

    &.isVisible {
      opacity: 1;
      pointer-events: initial;
    }

    &:after {
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-width: 6px;
      margin-left: -6px;
    }
    &--attachTop:after {
      bottom: 100%;
      border-bottom-color: $colorBase;
    }
    &--attachBottom:after {
      top: 100%;
      border-top-color: $colorBase;
    }

    button {
      color: white;
    }
  }
}
