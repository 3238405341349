.controls {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;

  background: #111;
  font-size: 12px;
  text-transform: capitalize;
  white-space: nowrap;
  color: #eee;

  display: flex;
  flex-direction: column;

  z-index: 1;

  .inputRange,
  .inputVectorRange {
    padding: 0 0 10px 0;

    label {
      display: block;
    }

    input[type="range"] {
      height: 21px;
      -webkit-appearance: none;
      margin: 3px 0 0 0;
      width: 100%;
      background: transparent;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: #888;
        border-radius: 3px;
        border: 0px solid #111;
      }

      &::-webkit-slider-thumb {
        border: 2px solid #888;
        height: 15px;
        width: 15px;
        border-radius: 15px;
        background: #111;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
      }
    }
  }

  .inputVectorRange {
    input[type="range"] {
      display: block;
    }
  }

  .inputCheckbox {
    position: relative;
    margin: 0 0 8px 0;

    input[type="checkbox"] {
      padding: 0;
      margin: 0;
      position: absolute;
      left: -999999px;
    }

    label {
      display: block;
      position: relative;
      padding-left: 22px;
      cursor: pointer;
      user-select: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border: 2px solid #888;
        border-radius: 12px;
      }
    }

    input[type="checkbox"]:checked ~ label:after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 6px;
      height: 6px;
      background: #999;
      border-radius: 6px;
    }

    & + .inputRange {
      margin-top: 10px;
    }
  }

  .inputRadio {
    position: relative;
    margin: 0 0 8px 0;

    input[type="radio"] {
      padding: 0;
      margin: 0;
      position: absolute;
      left: -999999px;
    }

    label {
      display: block;
      position: relative;
      padding-left: 22px;
      cursor: pointer;
      user-select: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border: 2px solid #888;
        border-radius: 12px;
      }
    }

    input[type="radio"]:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 6px;
      height: 6px;
      background: #999;
      border-radius: 6px;
    }

    & + .inputRange {
      margin-top: 10px;
    }
  }

  .inputFile {
    padding: 0 0 20px 0;
    text-transform: none;

    label {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 100%;
      border: 2px solid #999;
      border-radius: 30px;
      background: transparent;
      font-size: 12px;
      line-height: 1;
      color: white;
    }

    input[type="file"] {
      cursor: pointer;
      opacity: 0;
      width: 0;

      &:focus {
        outline: none;
      }
    }
  }

  .inputColor {
    padding: 0 0 10px 0;

    label {
      display: block;
    }

    &--picker {
      display: flex;
      align-items: center;
      padding: 5px 0;

      .rc-color-picker-wrap {
        background-color: white;
        border-radius: 50%;
        padding: 0;
        width: 20px;
        height: 20px;
        position: relative;
      }
      .rc-color-picker-trigger {
        border-radius: 50%;
        box-shadow: none;
        border: none;
        padding: 0;
      }
    }
    &--value {
      text-transform: initial;
      padding: 0 5px;
    }
  }

  .playControls {
    &--visibilities {
      position: fixed;
      left: 0;
      top: 25px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding-left: 150px;

      .inputCheckbox {
        margin-right: 40px;

        label {
          color: #333;

          &:before {
            border-color: #777;
          }
        }

        input[type="checkbox"]:checked ~ label:after {
          background-color: #777;
        }
      }
    }

    &--age {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: rgba(white, 0.1);
      font-size: 22px;

      &Title {
        font-size: 12px;
        color: #888;
        margin-bottom: 2px;
      }
    }

    &--buttons {
      padding: 20px 40px 10px 40px;
      display: flex;
      flex-flow: column nowrap;

      button {
        cursor: pointer;

        &:focus {
          outline: none;
        }

        &.buttonPlayPause {
          height: 36px;
          border: none;
          border-radius: 30px;
          background: rgba(white, 1);
          font-size: 12px;
          color: black;
          margin-bottom: 10px;
        }

        &.buttonStepper,
        &.buttonDownload {
          border: none;
          border-radius: 2px;
          background: transparent;
          font-size: 12px;
          color: #999;
          text-decoration: underline;
        }
      }
    }

    &--inputs {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 0 40px;
    }
  }
}
