$contentWidth: 75vw;
$colorBase: black;

$borderWidthMin: 2px;
$borderWidthScale: 0.22vw;
$borderWidthMax: 4px;

$fontStack: forma-djr-text, sans-serif;
$fontSizeMin: 20px;
$fontSizeMax: 200px;
$fontSizeScale: 2.3vw;
$fontWeightBase: 400;
$fontWeightBold: 500;
$fontLineHeightBase: 1.25;
