$colorElements: #6f5b2a;

/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url("./fonts/lato-v16-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/lato-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url("./fonts/lato-v16-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/lato-v16-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.keyboardSizeWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.keyboard {
  box-sizing: border-box;
  position: absolute;
  width: 1366px;
  height: 1024px;
  padding: 40px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto auto;
  transform-origin: top left;

  font-family: "Lato";
  font-weight: 700;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
  line-height: 1;

  .inputs {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;

    .label {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
      margin-bottom: 40px;

      font-family: "Lato";
      font-weight: 500;
      font-kerning: normal;
      font-variant-ligatures: common-ligatures contextual;
      font-feature-settings: "kern", "liga", "clig", "calt";
      line-height: 1;
      font-size: 56px;
      line-height: 40px;
      text-transform: uppercase;
      color: $colorElements;

      &Minus,
      &Plus {
        width: 40px;
        height: 40px;
        position: relative;
      }

      &IconLine {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 5px;
        background: $colorElements;
      }

      &Plus .labelIconLine:nth-child(2) {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    input[type="range"] {
      height: 21px;
      -webkit-appearance: none;
      margin: 10px 0;
      width: 100%;
      background: transparent;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: $colorElements;
        border-radius: 0;
        border: 0;
      }

      &::-webkit-slider-thumb {
        position: relative;
        box-shadow: 0;
        border: none;
        height: 46px;
        width: 46px;
        border: 5px solid transparent;
        border-radius: 50%;
        background: $colorElements;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -22px;
      }
    }
  }

  .message {
    align-self: flex-end;
    position: relative;
    margin: 40px 0;
    padding: 40px 0;
    background: transparent;
    border: 5px solid $colorElements;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;

    .remainingCharacterCount {
      position: absolute;
      top: 15px;
      right: 15px;
      text-align: right;
      font-weight: 700;
      font-size: 20px;
      color: rgba($colorElements, 1);
    }

    .currentMessage {
      width: auto;
      height: 98px;
      text-align: center;
      font-size: 92px;
      line-height: 98px;
      font-weight: 500;
      color: $colorElements;
    }

    .pseudoCursor {
      width: 5px;
      height: 98px;
      display: inline-block;
      background: $colorElements;
      animation: blink 1s step-end infinite;
    }
  }

  .react-simple-keyboard {
    align-self: flex-end;
    max-width: 100%;
    padding: 0;
    background: transparent;

    .hg {
      &-row {
        margin-bottom: 10px;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0 10px;
        justify-content: start;

        &:nth-child(1) {
          grid-template-columns: repeat(10, 100px) 1fr;
        }

        &:nth-child(2) {
          grid-template-columns: 44px repeat(10, 100px) 1fr;
        }

        &:nth-child(3) {
          grid-template-columns: repeat(10, 100px) 1fr;
        }

        &:nth-child(4) {
          grid-template-columns: 100%;
        }
      }

      &-button {
        background: $colorElements;
        box-shadow: none;
        border: none;
        margin: 0 !important;
        font-family: "Lato";
        font-weight: 700;
        font-kerning: normal;
        font-variant-ligatures: common-ligatures contextual;
        font-feature-settings: "kern", "liga", "clig", "calt";
        line-height: 1;
        font-size: 28px;
        color: #fff;
        text-shadow: none;
        transition: transform 0.1s ease, background-color 0.15s ease;
        height: 100px;
        width: 100px;

        &.hg-button-bksp,
        &.hg-button-space,
        &[data-skbtnuid="default-r1b11"],
        &[data-skbtnuid="default-r2b10"] {
          letter-spacing: 1px;
          width: 100%;
        }

        &[data-skbtnuid="default-r1b0"] {
          width: 45px;
        }
      }

      &-activeButton {
        background-color: lighten($colorElements, 5%) !important;
        box-shadow: none !important;
        transform: translateY(2px);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
