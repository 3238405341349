@import "./variables.scss";

.scaffoldUpload {
  .input--file {
    pointer-events: initial;

    .brainhub-file-input__input--hidden {
      display: none;
    }
  }

  .brainhub-drop-area__button {
    position: relative;
    height: clamp($fontSizeMin * 1.6, $fontSizeScale * 1.6, $fontSizeMax * 1.6);
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border: none;
    font-family: $fontStack;
    font-size: clamp($fontSizeMin, $fontSizeScale, $fontSizeMax);
    font-weight: $fontWeightBase;
    letter-spacing: 0.05vw;
    color: $colorBase;

    &:focus {
      outline: none;
    }
  }

  .brainhub-file-info__metadata {
    font-size: clamp(
      $fontSizeMin * 0.5,
      $fontSizeScale * 0.5,
      $fontSizeMax * 0.5
    );
    margin-bottom: clamp(
      $fontSizeMin * 0.5,
      $fontSizeScale * 0.5,
      $fontSizeMax * 0.5
    );
  }
}
