@import "./variables.scss";

.colorSettings {
  &Colors {
    margin-top: 10px;
    display: grid;
    grid-auto-flow: column;
    gap: clamp($borderWidthMin * 4, $borderWidthScale * 4, $borderWidthMax * 4);
    align-content: center;
    justify-content: center;
  }

  &Color {
    width: clamp(
      $fontSizeMin * 0.75,
      $fontSizeScale * 0.75,
      $fontSizeMax * 0.75
    );
    height: clamp(
      $fontSizeMin * 0.75,
      $fontSizeScale * 0.75,
      $fontSizeMax * 0.75
    );
    border-radius: clamp(
      $fontSizeMin * 0.75,
      $fontSizeScale * 0.75,
      $fontSizeMax * 0.75
    );
    cursor: pointer;

    &.isSelected {
      box-shadow: 0 0 0
        clamp($borderWidthMin, $borderWidthScale, $borderWidthMax)
        rgba(white, 1);
    }
  }

  .colorPicker {
    .compact-picker {
      padding: 0 !important;

      div:nth-child(1) {
        display: grid !important;
        grid-template-columns: repeat(auto-fit, 15px) !important;
        gap: 5px !important;
        margin-bottom: 10px !important;

        span {
          & > div {
            margin-right: 0 !important;
            margin-bottom: 0 !important;
            border-radius: 50% !important;
          }
        }
      }

      div:nth-child(2) {
        display: grid !important;
        grid-auto-flow: column !important;
        gap: 5px !important;
        align-content: center !important;
        justify-content: center !important;
        padding: 0 0 5px 0 !important;

        > div {
          display: block !important;
          width: auto !important;
          flex: none !important;

          &:nth-child(2) {
            width: 100px !important;

            span {
              display: none !important;
            }
          }
        }

        input {
          height: 2.5em !important;
          border-radius: 2.5em !important;
          padding: 0 10px !important;
          background: rgba(white, 0.1) !important;
          font-family: $fontStack !important;
          font-weight: $fontWeightBase !important;
          font-style: normal !important;
          font-size: 12px !important;
          letter-spacing: 1px !important;
          color: white !important;

          & ~ span {
            height: 2em !important;
            display: inline-flex !important;
            align-items: center !important;
            justify-content: center !important;
            font-family: $fontStack !important;
            font-weight: $fontWeightBase !important;
            font-style: normal !important;
            font-size: 12px !important;
            letter-spacing: 1px !important;
          }
        }
      }
    }
  }
}
